.verificationcard {
    padding: 60px;
    border-radius: 4px;
    display: inline-block;
    margin: 60px auto;
}
.verificationicon {
    border-radius:200px; 
    height:200px; 
    width:200px; 
    background: #F8FAF5; 
    margin:0 auto;
}
.checkmark {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}